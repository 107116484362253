/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import PropTypes from "prop-types"
import { StoreProvider } from "./src/store/useStore"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}

// Add polyfill needed for https://www.npmjs.com/package/gatsby-background-image#important
// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
