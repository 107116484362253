// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("/opt/build/repo/src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-book-template-index-js": () => import("/opt/build/repo/src/templates/BookTemplate/index.js" /* webpackChunkName: "component---src-templates-book-template-index-js" */),
  "component---src-templates-creator-template-index-js": () => import("/opt/build/repo/src/templates/CreatorTemplate/index.js" /* webpackChunkName: "component---src-templates-creator-template-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bocker-js": () => import("/opt/build/repo/src/pages/bocker.js" /* webpackChunkName: "component---src-pages-bocker-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("/opt/build/repo/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-sok-js": () => import("/opt/build/repo/src/pages/sok.js" /* webpackChunkName: "component---src-pages-sok-js" */),
  "component---src-pages-upphovspersoner-js": () => import("/opt/build/repo/src/pages/upphovspersoner.js" /* webpackChunkName: "component---src-pages-upphovspersoner-js" */)
}

