// https://dev.to/ramsay/build-a-redux-like-global-store-using-react-hooks-4a7n
import React, { createContext, useReducer, useContext } from "react"
import { listsInitialState, listsActions } from "./listsActions"

const initialState = {
  ...listsInitialState,
}

const StoreContext = createContext(initialState)

const Actions = {
  ...listsActions,
}

const reducer = (state, action) => {
  const { payload } = action
  const act = Actions[action.type]
  const update = act(state, payload)
  return { ...state, ...update }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = store => {
  const { state, dispatch } = useContext(StoreContext)
  return { state, dispatch }
}
