export const listsInitialState = {
  booksToShow: 30,
  creatorsToShow: 30,
  selectedAgeGroups: [],
  selectedBookCategories: [],
  selectedCreatorRoles: null,
  selectedLastNameLetters: [],
  creatorType: [],
  bookSearchQuery: "",
  bookSearchResults: null,
  searchQuery: "",
}

export const listsActions = {
  addBooksToShow: state => ({ booksToShow: state.booksToShow + 30 }),
  addCreatorsToShow: state => ({ creatorsToShow: state.creatorsToShow + 30 }),
  setAgeGroups: (state, payload) => ({
    ...state,
    selectedAgeGroups: payload || [],
  }),
  setBookCategories: (state, payload) => ({
    ...state,
    selectedBookCategories: payload || [],
  }),
  setCreatorRoles: (state, payload) => ({
    ...state,
    selectedCreatorRoles: payload || null,
  }),
  setLastNameLetters: (state, payload) => ({
    ...state,
    selectedLastNameLetters: payload || [],
  }),
  resetBooksList: state => ({
    ...state,
    booksToShow: 30,
    selectedAgeGroups: [],
    selectedBookCategories: [],
    bookSearchQuery: "",
    bookSearchResults: null,
  }),
  setBookSearchResponse: (state, payload) => ({
    ...state,
    bookSearchQuery: payload.searchQuery,
    bookSearchResults: payload.searchResults,
    booksToShow: 30,
  }),
  setCreatorsSearchResponse: (state, payload) => ({
    ...state,
    creatorsSearchQuery: payload.searchQuery,
    creatorsSearchResults: payload.searchResults,
    creatorsToShow: 30,
  }),
  resetCreatorsList: state => ({
    ...state,
    creatorsToShow: 30,
    selectedCreatorRoles: null,
    selectedLastNameLetters: [],
    creatorsSearchQuery: "",
    creatorsSearchResults: null,
  }),
  setSearchResponse: (state, payload) => ({
    ...state,
    searchQuery: payload.searchQuery,
    searchResults: payload.searchResults,
    resultsToShow: 6,
  }),
  resetSearchList: state => ({
    ...state,
    searchQuery: "",
    searchResults: null,
  }),
}
